import React, { CSSProperties, useEffect } from 'react';

import { useOpenGraphLink } from './useOpenGraphLink';

export const PartyLink = props => {
    const { storeId, partyId, userId } = props.match.params;
    const {
        isIOSMobile,
        goToOpenGraphLink,
        isAndroidMobile,
        goToFallbackLink
    } = useOpenGraphLink();

    useEffect(() => {
        goToOpenGraphLink(`snackpass://stores/${storeId}/${partyId}/${userId}`);
    }, [storeId, partyId, userId]);

    const gestureOpenLink = () => {
        goToOpenGraphLink(`snackpass://stores/${storeId}/${partyId}/${userId}`);
        setTimeout(goToFallbackLink, 1000);
    };

    const iosComponent = (
        <>
            <label style={styles.description}>
                Click <b style={{ color: '#008DFF' }}>Open</b> in the popup to
                view this party in the Snackpass app! 🎉
            </label>
            <label style={styles.ifYouMissedIt}>
                If you missed it, refresh the page.
            </label>
        </>
    );
    const androidComponent = (
        <span>
            Open the{' '}
            <a href="#" onClick={gestureOpenLink}>
                Snackpass app
            </a>{' '}
            to view this party! 🎉
        </span>
    );

    return (
        <div style={styles.container}>
            {isIOSMobile ? iosComponent : androidComponent }
        </div>
    );
};

const styles = {
    container: {
        padding: 30,
        display: 'flex',
        flexDirection: 'column'
    } as CSSProperties,
    description: {
        fontSize: 25,
        fontWeight: 400
    } as CSSProperties,
    ifYouMissedIt: {
        marginTop: 15,
        fontWeight: 400
    } as CSSProperties
};

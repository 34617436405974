import { isAndroid, isIOS } from 'react-device-detect';

export const useOpenGraphLink = (
    fallbackIOS = 'https://apps.apple.com/us/app/snackpass/id1196524786',
    fallbackNonIOS = 'https://play.google.com/store/apps/details?id=com.snackpassapp&hl=en_US&gl=US'
) => {
    const getFallbackLink = () => {
        if (isIOS) {
            return fallbackIOS;
        }
        return fallbackNonIOS;
    };
    const goToFallbackLink = () => {
        window.location.href = getFallbackLink();
    };

    /*
    The logic here is a bit clunky, because newer versions of android chrome will not allow you to redirect to a deeplink without a user gesture.
    It will allow a redirect if you entered this page by clicking a link, but not if you copy pasted the link into your address bar.
    It's also not possible (to my knowledge) to tell whether the link failed because you didn't get to the page through a gesture or you don't have the app.

    So, instead of immediately redirecting on failure for the android case, we have a link for the user to press (gesture) and then redirect to playstore
    if that link fails.
    */
    const goToOpenGraphLink = url => {
        try {
            if (isIOS || url.substring(0, 4) === 'http') {
                window.location.href = url;
            } else if (isAndroid) {
                window.location.href = url; // this will work for older versions of chrome / other browsers that support the direct redirect
                setTimeout(() => {
                    const splitUrl = url.split('://');
                    const scheme = splitUrl[0];
                    const path = splitUrl[1];

                    // the intent url that is used for chrome
                    const intentUrl = `intent://${path}#Intent;scheme=${scheme};package=com.snackpassapp;end;`;
                    window.location.href = intentUrl;
                }, 1000);
            } else {
                throw new Error('link cannot be opened from this device.');
            }
        } catch {
            goToFallbackLink();
        }
    };
    const isIOSMobile = isIOS;
    const isAndroidMobile = isAndroid;
    return {
        isIOSMobile,
        goToOpenGraphLink,
        isAndroidMobile,
        goToFallbackLink
    };
};

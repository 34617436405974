import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import MobileDetect from 'mobile-detect';

const appleAppStoreLink =
    'https://apps.apple.com/us/app/snackpass/id1196524786';
const googlePlayStoreLink =
    'https://play.google.com/store/apps/details?id=com.snackpassapp&hl=en_US';

export const RouteToAppStore = () => {
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        routeUserToAppStoreOrHome();
    }, []);
    return null;
};

const routeUserToAppStoreOrHome = () => {
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.os() === 'iOS') {
        window.location.href = appleAppStoreLink;
    } else if (md.os() === 'AndroidOS') {
        window.location.href = googlePlayStoreLink;
    } else {
        window.location.href = appleAppStoreLink;
    }
};

import React, { CSSProperties } from 'react';

import { SystemColors } from '@snackpass/design-system';
import logo_horizontal from '../../assets/images/logo_horizontal.png';
import { isMobile } from 'react-device-detect';
import { AnnouncementBanner } from '../Web2';

const MOBILE_SPLINE =
    'https://my.spline.design/3dspiconmobile-62d61a24de003335b824cc7028e7c353/';
const WEB_SPLINE =
    'https://my.spline.design/3dspicon-df0e709afe2cd10d144e3d520bf41b3b/';

export const Home = () => (
    <div style={styles.container}>
        {/* <AnnouncementBanner /> */}
        <section style={styles.splineContainer}>
            <iframe
                src={isMobile ? MOBILE_SPLINE : WEB_SPLINE}
                frameBorder="0"
                width="100%"
                height="100%"
            ></iframe>
        </section>
        <section style={styles.footer}>
            <img style={styles.logo} src={logo_horizontal}></img>
            <div style={styles.linkContainer}>
                <a
                    className="home-link"
                    style={styles.link}
                    href="https://partnerwithsnackpass.typeform.com/signup?utm_source=&utm_medium=xxxxx&utm_campaign=&utm_term=xxxxx&utm_content=xxxxx#inbound=xxxxx"
                >
                    Restaurants
                </a>
                {isMobile ? (
                    <a className="home-link" style={styles.link} href="/app">
                        Download the App
                    </a>
                ) : null}
                <a
                    className="home-link"
                    style={styles.link}
                    href="https://jobs.snackpass.co"
                >
                    Jobs
                </a>
                <a
                    className="home-link"
                    style={styles.link}
                    href="https://legal.snackpass.co"
                >
                    Legal
                </a>
                <a
                    className="home-link"
                    style={{ ...styles.link, cursor: 'pointer' }}
                    onClick={() => {
                        // @ts-ignore
                        window.Intercom('show');
                    }}
                >
                    Contact Us
                </a>
            </div>
        </section>
    </div>
);

const styles: Record<string, CSSProperties> = {
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
    },
    splineContainer: {
        flex: 1,
        backgroundSize: 'cover'
    },
    logo: {
        width: isMobile ? 120 : 150,
        marginTop: isMobile ? 30 : 0,
        marginBottom: isMobile ? 50 : 0
    },
    footer: {
        backgroundColor: SystemColors.v1.sesame,
        color: SystemColors.v1.white,
        fontFamily: 'Galano Grotesque',
        display: 'flex',
        alignItems: 'center',
        padding: 30,
        paddingLeft: 50,
        paddingRight: 50,
        fontSize: 16,
        justifyContent: 'space-between',
        flexDirection: isMobile ? 'column-reverse' : 'row'
    },
    linkContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: isMobile ? 'column' : 'row'
    },
    link: {
        color: SystemColors.v1.white,
        margin: 10,
        marginLeft: 20,
        marginRight: 20
    }
};

// Looks like this --> https://www.figma.com/file/34NIRZMb2G3HDHMNBsecVh/Snackpass.co-Landing-Page?node-id=1509%3A9114

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PartyLink } from './openGraphLinks/PartyLink';
import { RouteToAppStore } from './RouteToAppStore';
import './stylesheets/index.css';
import { Home } from './Views/Home';

const App = () => (
    <section>
        <Switch>
            <Route
                exact
                from="/thon"
                component={() => {
                    window.location.href =
                        'https://forms.gle/XX4pz24UScdR2yeQ8';
                    return null;
                }}
            />
            <Route exact path="/" component={Home} />
            <Route path="/app" component={RouteToAppStore} />
            {/* -- open graph links -- */}
            <Route
                path="/party/:storeId/:partyId/:userId"
                component={PartyLink}
            />
            {/* -- end of open graph links -- */}
            <Route exact path="/*" component={Home} />
        </Switch>
    </section>
);

export default App;
